<!-- eslint-disable global-require -->
<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="employeesForm.name"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="employeesForm.email"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <b-form-group>

            <validation-provider
              name="Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="employeesForm.password"
                  autocomplete="off"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="ep"
                  placeholder="Password"
                  @focus="handleType"
                  @blur="handleType"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Partners"
          >
            <b-form-group
              label="Partners"
              label-for="Partners"
            >
              <v-select
                v-model="employeesForm.partner_id"
                :options="partners"
                :filterable="false"
                label="name"
                :reduce="partners => partners.id"
                @search="searchPartners"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="employeesForm.partner_id"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="Branches"
          >
            <b-form-group
              label="Branches"
              label-for="Branches"
            >
              <v-select
                v-model="employeesForm.branch_id"
                :options="branches"
                :filterable="false"
                label="name"
                :reduce="branches => branches.id"
                @search="searchBranches"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="blocked"
            label-for="blocked"
          >
            <b-form-checkbox
              id="blocked"
              v-model="employeesForm.blocked"
              value="1"
              unchecked-value="0"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="employeesForm.avatar_url"
                  width="100"
                  height="100"
                />
              </b-link>
            <!--/ avatar_url -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Image
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png"
                :hidden="true"
                plain
                @change="changeProfile($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addemployees()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addemployees()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import vSelect from 'vue-select'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    vSelect,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      loading: false,
      userLocation: {},
      position: {},
      branches: [],
      partners: [],
      errorMsg: '',
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      passwordFieldType: 'text',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const employeesForm = ref({
      avatar_url: null,
      name: '',
      partner_id: null,
      branch_id: null,
      blocked: 0,
      password: '',
    })
    // eslint-disable-next-line camelcase
    const avatar_url = ref('')

    return {
      getValidationState,
      employeesForm,
      avatar_url,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'employeesForm.partner_id': function (newVal) {
      this.getBranches(newVal)
    },
  },
  mounted() {
    this.showemployees()
    this.employeesForm.password = ''
    this.getPartners()
  },
  methods: {
    handleType(event) {
      const { srcElement, type } = event
      const { value } = srcElement

      if (type === 'blur' && !value) {
        this.passwordFieldType = 'text'
      } else {
        this.passwordFieldType = 'password'
      }
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.avatar_url = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.employeesForm.avatar_url = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.avatar_url = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    async searchPartners(searchQuery) {
      await axios.get(`admin/partners?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.partners = res.data?.data.partners
        }
      })
    },

    async getPartners() {
      await axios.get('admin/partners').then(res => {
        if (res.status === 200) {
          this.partners = res.data?.data.partners
        }
      })
    },
    async searchBranches(searchQuery) {
      await axios.get(`admin/branches?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.branches = res.data?.data.branches
        }
      })
    },

    async getBranches(partnerId) {
      await axios.get(`admin/branches?partner_id=${partnerId}`).then(res => {
        if (res.status === 200) {
          this.branches = res.data?.data.branches
        }
      })
    },
    showemployees() {
      if (this.$route.params.id) {
        axios.get(`admin/employees/${this.$route.params.id}`).then(res => {
          this.employeesForm = res.data.data.employee
          this.employeesForm.password = ''
        })
      } else {
        return false
      }
      return true
    },
    addemployees() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.employeesForm) {
          if (key !== 'avatar_url') {
            formData.append(key, this.employeesForm[key])
          }
        }
        formData.delete('partner_name')
        formData.delete('branch_name')
        if (this.avatar_url) {
          formData.append('avatar_url', this.avatar_url)
        }
        axios.post(`admin/employees/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'partners-employees' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.employeesForm) {
          if (key !== 'avatar_url') {
            formData.append(key, this.employeesForm[key])
          }
        }
        if (this.avatar_url) {
          formData.append('avatar_url', this.avatar_url)
        }

        this.loader = true
        axios.post('admin/employees', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'partners-employees' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">

  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
